import { debounce } from 'lodash'
import { useState, useEffect } from 'react'

/**
 * @interface WindowSize
 * @description - stores the size of the viewport
 */

interface WindowSize {
  windowHeight: number
  windowWidth: number
}

/**
 * @function useWindowSize
 * @description - react hook to use the size of the viewport
 */

export const useWindowSize = (): WindowSize => {

  const [windowSize, setWindowSize] = useState({
    windowWidth: 0,
    windowHeight: 0,
  })

  useEffect(() => {

    const handleResize = debounce(() => {

      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      })

    }, 100)

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)

  }, [])

  return windowSize

}
